import { render, staticRenderFns } from "./ServizioClienti.vue?vue&type=template&id=99e9ee0a&scoped=true&"
import script from "./ServizioClienti.vue?vue&type=script&lang=js&"
export * from "./ServizioClienti.vue?vue&type=script&lang=js&"
import style0 from "./ServizioClienti.vue?vue&type=style&index=0&id=99e9ee0a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99e9ee0a",
  null
  
)

export default component.exports