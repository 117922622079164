<template>
<div>
  <!--<div class="full-width hero">
    <div class="col-12">
      <img src="@/assets/home1.png" class="cover-img" />
    </div>
  </div>-->

  <div class="full-width cart">
    <div class="container m-b-5">

      <div class="col-3 no-mobi">
        <div class="full-width spacer"></div>
      </div>

      <div class="col-6 mobi-space">

        <div class="full-width spacer no-mobi"></div>
        <h2 class="shipping-title">Resi e rimborsi</h2>

        <p>Le cose possono andare storte a volte, risolviamole insieme.</p>

        <br><br>

        <div class="row spaced">
          <div class="col-6">
            <input type="text" placeholder="Nome" v-model="reso.name" />
          </div>
          <div class="col-6">
            <input type="text" placeholder="Cognome" v-model="reso.surname" />
          </div>
        </div>

        <div class="row spaced">
          <div class="col-6">
            <input type="text" placeholder="Email" v-model="reso.email" />
          </div>
          <div class="col-6">
            <input type="text" placeholder="Numero d'ordine" v-model="reso.orderNumber" />
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12">
            <p class="align-center" style="margin: 10px auto;">Il numero d'ordine &egrave; il numero di riferimento del tuo ordine di acquisto. Lo trovi nell’oggetto della mail di conferma ordine che hai ricevuto.</p>
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12">
            <label style="margin-bottom: 5px !important; display: block;">DESCRIZIONE DEL PROBLEMA</label>
            <textarea placeholder="Aiutaci a risolvere il problema riscontrato, indica tutte le informazioni utili" rows="8" v-model="reso.description"></textarea>
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12">
            <label style="margin-bottom: 5px !important; display: block;">IMMAGINI ALLEGATE</label>
            <input type="file" multiple @change="checkFiles" />
          </div>
        </div>

        <!--<div class="row spaced">
          <div class="col-12">
            <label style="margin-bottom: 5px !important; display: block;">SOLUZIONE PROPOSTA</label>
            <textarea placeholder="Indicaci come risolveresti il problema riscontrato" rows="8" v-model="reso.solution"></textarea>
          </div>
        </div>-->

        <div class="row spaced">
          <div class="col-12">
            <p>Letta e compresa <router-link to="/politica-di-privacy">l'informativa Privacy</router-link></p>
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12 align-center">
            <div class="button" @click="handleReso()">Invia la tua richiesta</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ServizioClienti',

  data: function () {
    return {
      reso: {
        name: '',
        surname: '',
        email: '',
        orderNumber: '',
        description: '',
        solution: '',
        files: []
      }
    }
  },

  methods: {
    loadFile (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.files.push({
          base64EncodedAttachment: reader.result,
          fileName: file.name,
          mimeType: file.type,
          sizeInBytes: file.size
        })
      };

      reader.onerror = (error) => {
        console.log('Error: ', error)
      };
    },
    async checkFiles (evt) {
      let control = evt.target
      let files = control.files
      let maxSize = 1024*1024*5
      let totSize = 0

      this.files = []

      if (files.length > 4) {
        evt.target.value = ''
        this.$toast.open('Puoi inserire un massimo di 4 files')
        return false
      }

      for (let i = 0; i < files.length; i++) {
        totSize += parseInt(files[i].size)
      }

      if (totSize > maxSize) {
        evt.target.value = ''
        this.$toast.open('Puoi caricare un massimo di 3MB negli allegati')
        this.files = []
        return false
      }

      for (let i = 0; i < files.length; i++) {
        this.loadFile(files[i])
      }

      setTimeout(() => {
        console.log(this.files)
      }, 1500)
    },
    async handleReso () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (this.reso.name.length < 3) {
        this.$toast.open('Il campo nome è obbligatorio')
        return false
      }

      if (this.reso.surname.length < 3) {
        this.$toast.open('Il campo cognome è obbligatorio')
        return false
      }

      if (!re.test(String(this.reso.email).toLowerCase())) {
        this.$toast.open('Il campo email non è valido')
        return false
      }

      /*if (this.reso.orderNumber.length < 3) {
        this.$toast.open('Descrivi il tuo problema')
        return false
      }*/

      if (this.reso.description.length < 3) {
        this.$toast.open('Descrivi il tuo problema')
        return false
      }



      try {
        await axios.post(this.$config.backendUrl + 'index.php?action=reso', JSON.stringify({
          toAddresses: [
            'alessandro.caligaris1991@gmail.com',
            'alessandro@zprotocol.com',
          ],
          subject: 'Ordine ' + this.reso.orderNumber + ' - Richiesta di reso / rimborso',
          base64EncodedPlainTextBody: btoa(`Nome: ${this.reso.name}\n
Cognome: ${this.reso.surname}\n
Email: ${this.reso.email}\n
Numero d'ordine: ${this.reso.orderNumber}\n
Descrizione del problema: ${this.reso.description}\n
`),
          base64EncodedHtmlBody: btoa(`Nome: ${this.reso.name}<br>
Cognome: ${this.reso.surname}<br>
Email: ${this.reso.email}<br>
Numero d'ordine: ${this.reso.orderNumber}<br>
Descrizione del problema: ${this.reso.description}
`),
          scope: 'CUSTOMER_CARE',
          attachments: this.files
        }))

        this.$toast.open('La tua richiesta di reso è stata inviata correttamente')
      } catch (e) {
        this.$toast.open('Qualcosa è andato storto! Riprova più tardi')
      }
    }
  }
}
</script>

<style scoped>
.cart h1 {
  font-weight: 600;
  font-size: 38px;
  margin: 40px 0;
  width: 100%;
}

.cart-table {
  width: 100%;
  border-style:none none solid;
  border-collapse: collapse;
  margin-bottom: 46px;
  border: 0px solid black;
}

.cart .container.column {
  flex-direction: column;
}

thead tr {
  border-bottom: 1px solid #cfcfcf;
  height: 40px;
  line-height: 40px;
}

thead th {
  text-align: left;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

tbody tr {
  height: 120px;
  border-bottom: 1px solid #cfcfcf;
}

.cart-image img {
  width: 90px;
  height: 90px;
  border: 1px solid #eee;
}

.cart-name {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.cart-discount {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.prod-qty {
  display: inline-block;
  margin-right: 15px;
}

.fa-trash {
  cursor: pointer;
}

.coupon-code {
  background-color: #fff;
  text-align: center;
  height: 32px;
  line-height: 32px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.button {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.coupon-description {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
}

hr {
  border: 1px solid #e3e3e3;
}

.subtotal-row {
  height: 32px;
  line-height: 32px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.totals {
  padding-left: 40px;
}

.interested-in {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 0.3px;
}

input[type="text"],
input[type="password"],
textarea
{
  width: 100%;
  margin-right: 15px;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 16px;
}

textarea {
  height: 200px;
  line-height: 25px;
}

.row.spaced {
  margin-bottom: 20px;
}

.row.spaced .col-6,
.row.spaced .col-12 {
  padding-right: 15px;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.payment-method {
  margin-top: 125px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: neue-haas-grotesk-display, sans-serif;
  text-transform: uppercase;
}

.fattura {
  margin-bottom: 60px;
}

.shipping-title {
  margin: 0 0 10px 0;
}

.shipping-title + hr {
  margin-bottom: 20px;
}

@media (max-width: 680px) {
  .shipping-title {
    margin-top: 25px;
    text-align: center;
    margin-bottom: 25px;
  }

  .shipping-title.more-margin {
    margin-top: 50px;
  }

  .row.spaced {
    display: flex;
    justify-content: space-between;
  }

  .row.spaced .col-6,
  .row.spaced .col-12 {
    padding-right: 0;
  }

  .row.spaced .col-6 {
    width: 48%;
  }
}
</style>



